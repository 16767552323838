.root {
  & header[class*='PageBuilder_topbar'] {
    background-color: var(--colorWhite);
    box-shadow: var(--boxShadowLight);
  }
}

.logo {
  object-fit: scale-down;
  height: 100px;
}
